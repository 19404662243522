import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useUserContext } from "../context/userContext";
import { CloseButton } from "./CloseButton";

const DeleteModal = ({
  name,
  toggleAdd,
  deleteModal,
  toggleDelete,
  setMeds,
  rowId,
  submitAddMed,
  selectedMed,
  setFormEdited,
  isSideBarRoute = false
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { userState } = useUserContext();
  const { user } = userState;

  const onSubmit = () => {
    setIsSubmitting(true);
    if (setMeds) setMeds((state) => state.filter((item, idx) => idx !== rowId));
    if (setFormEdited) setFormEdited(true);
    if (submitAddMed) {
      const { medicationSchedule, ...newObj } = selectedMed;
      submitAddMed(
        {
          ...newObj,
          status: 1,
          medicationApprovalStatus: 1,
          medicationId: selectedMed.id
        },
        setIsSubmitting,
        toggleDelete
      );
    } else {
      setIsSubmitting(false);
      toggleDelete();
      toggleAdd();
    }
  };
  return (
    <Modal
      isOpen={deleteModal}
      toggle={toggleDelete}
      className={classNames(
        { "sidebar-modal": isSideBarRoute && user && !user.isSmartSession },
        "center-modal"
      )}
      autoFocus={false}
    >
      <ModalHeader
        toggle={toggleDelete}
        close={<CloseButton toggle={toggleDelete} />}
      >
        Are you sure you want to remove {name}?
      </ModalHeader>
      <ModalBody>
        <div className="form-group d-flex flex-md-row flex-column align-items-center">
          <button
            type="button"
            className="btn btn-primary w-25 mr-md-4 mb-md-0 mb-4"
            onClick={onSubmit}
            disabled={isSubmitting}
            autoFocus
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Yes
          </button>
          <button
            type="button"
            className="btn btn-outline-primary w-25"
            onClick={toggleDelete}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  name: PropTypes.string,
  toggleAdd: PropTypes.func,
  deleteModal: PropTypes.bool,
  toggleDelete: PropTypes.func,
  setMeds: PropTypes.func,
  rowId: PropTypes.number,
  submitAddMed: PropTypes.func,
  selectedMed: PropTypes.object,
  setFormEdited: PropTypes.func,
  isSideBarRoute: PropTypes.bool
};

export { DeleteModal };
