import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Suspense, useMemo, useRef, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { TimePickerField } from ".";
import { timesOfDay } from "../_helpers";

export const CustomTimeOfDayPicker = ({
  idx,
  setFieldTouched,
  setFieldValue,
  values
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const datePickerRef = useRef(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const isValuePresent = (value) => {
    return values.medicationScheduleTimes.some(
      (time) => time.partOfDay?.label === value
    );
  };
  const isActive = (value) => {
    return values.medicationScheduleTimes[idx]?.partOfDay === value;
  };

  const setTimeValue = useMemo(() => {
    if (values.medicationScheduleTimes[idx]?.time) {
      return new Date(
        values.medicationScheduleTimes[idx]?.time
      ).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit"
      });
    } else if (values.medicationScheduleTimes[idx]?.partOfDay) {
      return values.medicationScheduleTimes[idx].partOfDay.label;
    }
    return "Set time";
  }, [values, idx]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        tabIndex={0}
        className="btn btn-link py-0 ml-2 mt-neg-2px"
      >
        {setTimeValue}
      </DropdownToggle>
      <DropdownMenu
        container={
          document.getElementsByClassName("modal")[
            document.getElementsByClassName("modal").length - 1
          ]
        }
        id="time-dropdown"
        className="py-0"
        onKeyDown={(e) => {
          if (e.target.classList.contains("react-datepicker__time-list-item")) {
            if (e.key === "ArrowDown" || e.key === "ArrowUp") {
              e.stopPropagation();
            }
            // if arrow left is pressed, move over to the first dropdown item
            if (e.key === "ArrowLeft") {
              e.preventDefault();
              const firstDropdownItem = document.querySelector(
                ".time-of-day-dropdown-item"
              );
              firstDropdownItem.focus();
            }
          }
        }}
      >
        <div className="d-flex">
          <div className="border-right">
            <DropdownItem header className="text-secondary small p-3">
              TIME OF DAY
            </DropdownItem>
            {timesOfDay.map((tod) => (
              <DropdownItem
                className={classnames(
                  {
                    active: isActive(tod)
                  },
                  "px-3 pb-1 pt-1 time-of-day-dropdown-item"
                )}
                key={tod.value}
                onClick={() => {
                  const fieldName = `medicationScheduleTimes[${idx}].partOfDay`;
                  const timeFieldName = `medicationScheduleTimes[${idx}].time`;

                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, tod);
                  setFieldTouched(timeFieldName, true);
                  setFieldValue(timeFieldName, "");
                }}
                disabled={isValuePresent(tod.label) && !isActive(tod)}
                onKeyDown={(e) => {
                  console.log(e.key);
                  // if tab key is pressed, move over to the time picker
                  if (e.key === "ArrowRight") {
                    let selectedTime = "12:00 PM";
                    if (values.medicationScheduleTimes[idx]?.time) {
                      selectedTime = new Date(
                        values.medicationScheduleTimes[idx]?.time
                      ).toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "2-digit"
                      });
                    }
                    e.preventDefault();
                    const timeList = document.querySelector(
                      ".react-datepicker__time-list"
                    );

                    const timeItem = Array.from(
                      timeList.querySelectorAll(
                        ".react-datepicker__time-list-item"
                      )
                    ).find((item) => item.innerText === selectedTime);
                    if (timeItem) {
                      timeItem.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                      });

                      timeItem.focus();
                    }
                  }
                }}
              >
                {tod.label}
              </DropdownItem>
            ))}
          </div>
          {/* <DropdownItem divider /> */}
          <div>
            <DropdownItem header className="text-secondary small p-3">
              SPECIFIC TIME
            </DropdownItem>
            {/* <DropdownItem> */}
            <Suspense fallback={<span></span>}>
              <TimePickerField
                onChange={(date) => {
                  const fieldName = `medicationScheduleTimes[${idx}].time`;
                  const todFieldName = `medicationScheduleTimes[${idx}].partOfDay`;
                  setFieldTouched(fieldName, true);
                  setFieldValue(fieldName, date);
                  setFieldTouched(todFieldName, true);
                  setFieldValue(todFieldName, "");
                }}
                id="time"
                datePickerRef={datePickerRef}
                name="time"
                inline={true}
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="h:mm aa"
                placeholderText={
                  Number(values.medicationScheduleTimes[idx].dose) !==
                    values.doseType?.value &&
                  values.medicationScheduleTimes[idx].dose
                    ? "Set time *"
                    : "Set time"
                }
                selected={values.medicationScheduleTimes[idx].time || ""}
                excludeTimes={
                  // exclude times that are in the times array unless it is the current time
                  values.medicationScheduleTimes
                    .filter((time, i) => i !== idx)
                    .map((time) => new Date(time.time))
                }
              />
            </Suspense>
          </div>
        </div>
        {/* </DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomTimeOfDayPicker.propTypes = {
  idx: PropTypes.number,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    medicationScheduleTimes: PropTypes.arrayOf(
      PropTypes.shape({
        partOfDay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        time: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.string
        ]),
        dose: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    ),
    doseType: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.number
      }),
      PropTypes.string,
      PropTypes.number
    ])
  })
};
