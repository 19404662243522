import classnames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { CustomInput, Table } from "reactstrap";
import { CloseButton } from "../_components";
import { ReactComponent as Caution } from "../images/caution.svg";
import { ReactComponent as Left } from "../images/chevron_left.svg";
import { ReactComponent as Right } from "../images/chevron_right.svg";
import { ReactComponent as GlyphSkip } from "../images/glyph-skip.svg";
import { ReactComponent as GlyphAfternoon } from "../images/glyph_afternoon.svg";
import { ReactComponent as GlyphCheck } from "../images/glyph_check.svg";
import { ReactComponent as GlyphEvening } from "../images/glyph_evening.svg";
import { ReactComponent as GlyphMorning } from "../images/glyph_morning.svg";
import { ReactComponent as GlyphNight } from "../images/glyph_night.svg";
import { ReactComponent as GlyphX } from "../images/glyph_x.svg";
import { ReactComponent as GlyphPerson } from "../images/nav-user.svg";
import { ReactComponent as SmartPhone } from "../images/smartphone.svg";
import { ReactComponent as PhoneCall } from "../images/phone-call.svg";
import { ReactComponent as Chat } from "../images/chat.svg";
import { ReactComponent as Comment } from "../images/comment.svg";
import { TableSkeletons } from "./TableSkeletons";
import { claimsAlertData } from "../components/patient";

const BodyInfo = ({
  morningMeds,
  noonMeds,
  eveningMeds,
  nightMeds,
  prnMeds
}) => {
  return (
    <div>
      {morningMeds.length > 0 ? (
        <div className="pt-2">
          <p className="py-3 px-5 bg-light mb-0">
            <GlyphMorning
              aria-label="morning"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Morning
          </p>
          <MedHistoryTable rows={morningMeds} />
        </div>
      ) : (
        <></>
      )}
      {noonMeds.length > 0 ? (
        <div className="pt-2">
          <p className="py-3 px-5 bg-light mb-0">
            <GlyphAfternoon
              aria-label="afternoon"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Afternoon
          </p>
          <MedHistoryTable rows={noonMeds} />
        </div>
      ) : (
        <></>
      )}
      {eveningMeds.length > 0 ? (
        <div className="pt-2">
          <p className="py-3 px-5 bg-light mb-0">
            <GlyphEvening
              aria-label="evening"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Evening
          </p>
          <MedHistoryTable rows={eveningMeds} />
        </div>
      ) : (
        <></>
      )}
      {nightMeds.length > 0 ? (
        <div className="pt-2">
          <p className="py-3 px-5 bg-light mb-0">
            <GlyphNight
              aria-label="night"
              className="mr-2 mb-2 glyph-dark w-1rem "
            />
            Night
          </p>
          <MedHistoryTable rows={nightMeds} />
        </div>
      ) : (
        <></>
      )}
      {prnMeds.length > 0 ? (
        <div className="pt-2">
          <p className="py-3 px-5 bg-light mb-0">
            <GlyphPerson
              aria-label="person"
              className="mr-2 mb-2 glyph-dark w-1rem h-1rem"
            />
            PRN
          </p>
          <MedHistoryTable rows={prnMeds} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
BodyInfo.propTypes = {
  morningMeds: PropTypes.array,
  noonMeds: PropTypes.array,
  eveningMeds: PropTypes.array,
  nightMeds: PropTypes.array,
  prnMeds: PropTypes.array
};

const MedHistoryTable = ({ rows }) => {
  return (
    <div className="px-5">
      <Table className="sidebar-table mb-0">
        <tbody className="font-weight-normal">
          {rows.map((row, i) => {
            return (
              <tr key={i}>
                <td className="w-25">{row.data.scheduledAtString || "N/A"}</td>
                <td className="w-45 white-space-prewrap">
                  {row.medicationName + ", " + row.data.strength}
                </td>
                <td className="w-30">
                  {row.data.isTaken === 1 && (
                    <span>
                      <GlyphCheck
                        aria-label="check"
                        className="glyph-success mr-1 w-12"
                      />
                      {row.data.takenAtString}
                    </span>
                  )}{" "}
                  {row.data.isTaken === 2 && (
                    <span>
                      <GlyphSkip
                        aria-label="skip"
                        className="glyph-warning-stroke mr-1 w-12"
                      />
                      Skipped
                    </span>
                  )}{" "}
                  {!row.data.isTaken && (
                    <span>
                      <GlyphX
                        aria-label="x"
                        className="glyph-danger mr-1 w-11"
                      />
                      Missed
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
MedHistoryTable.propTypes = {
  rows: PropTypes.array
};

const LeafletHeader = ({ leaflet, toggleSidebar }) => {
  return (
    <div className="d-flex align-items-center px-5 py-4 bg-light">
      <div className="">
        <h4>{leaflet.name}</h4>
        <h6>Leaflet</h6>
      </div>
      <CloseButton
        toggle={() => {
          toggleSidebar(false);
        }}
        popoverVersion={true}
        color="glyph-dark mb-0"
      />
    </div>
  );
};
LeafletHeader.propTypes = {
  leaflet: PropTypes.object,
  toggleSidebar: PropTypes.func
};

const DDISidebarHeader = ({
  reviewDrug,
  clinicalInteractions,
  toggleSidebar,
  medicationName
}) => {
  return (
    <div
      className={classnames(
        { "align-items-start px-7": reviewDrug },
        { "align-items-center px-5": !reviewDrug },
        "d-flex align-self-start pb-4 pt-5 bg-light"
      )}
    >
      <Caution
        aria-label="caution"
        className="glyph-danger mr-3 w-35px h-35px"
      />
      {/* DDI on patient page */}
      {reviewDrug && (
        <div className=" w-100">
          <h4 className="w-100 text-left text-wrap mb-1">
            {reviewDrug.drugName}
          </h4>
          <h6 className="w-100 text-left">
            {reviewDrug.drugSeverityLevel + " Interaction"}
          </h6>
        </div>
      )}
      {/* DDI on workflow */}
      {clinicalInteractions && (
        <div className=" w-100">
          <h4 className="w-100 text-left text-wrap mb-1">
            {clinicalInteractions.length + " Interaction(s)"}
          </h4>
          <h6 className="w-100  text-left">{medicationName}</h6>
        </div>
      )}

      <CloseButton
        toggle={() => {
          toggleSidebar(false);
        }}
        popoverVersion={true}
        color="glyph-dark mb-0"
      />
    </div>
  );
};
DDISidebarHeader.propTypes = {
  reviewDrug: PropTypes.object,
  clinicalInteractions: PropTypes.array,
  toggleSidebar: PropTypes.func,
  medicationName: PropTypes.string
};

const CalendarSidebarHeader = ({
  sidebarType,
  loadingCalendar,
  handlePreviousClick,
  handleNextClick,
  selectedEvent,
  takenDayCount,
  dayCount,
  startDay,
  takenMeds,
  totalMeds,
  toggleSidebar
}) => {
  return (
    <div
      className={classnames(
        "align-items-center px-5 d-flex align-self-start  pb-4 pt-5 bg-light"
      )}
    >
      <div className="d-flex">
        <button
          disabled={loadingCalendar}
          className="btn btn-link pr-1 pl-0"
          onClick={handlePreviousClick}
        >
          <Left aria-label="left" className="glyph-dark" />
        </button>

        <button
          disabled={loadingCalendar}
          className="btn btn-link float-right pr-5"
          onClick={handleNextClick}
        >
          <Right aria-label="right" className="glyph-dark" />
        </button>
        <div>
          <div className="text-left text-wrap  mr-5">
            {sidebarType === "event-history" && (
              <>
                {loadingCalendar ? (
                  <div className="w-300px">
                    <div className="ph-item bg-light border-0 mb-1 ">
                      <div className="ph-col-12 pl-0">
                        <div className=" ph-row">
                          <div className="ph-col-8 big mb-1"></div>
                        </div>
                        <div className=" ph-row">
                          <div className="ph-col-5 mb-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <h4 className="mb-1">
                      <span>
                        {dayjs(selectedEvent.date).format("dddd, MMMM D")}
                      </span>
                    </h4>
                    <h6>
                      {takenDayCount}/{dayCount} Meds Taken
                    </h6>
                  </>
                )}
              </>
            )}
            {sidebarType === "calendar" ? (
              <>
                <h4 className="mb-1">{dayjs(startDay).format("MMMM YYYY")}</h4>
                <h6>
                  {takenMeds}/{totalMeds} Meds Taken
                </h6>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <CloseButton
        toggle={() => toggleSidebar(false)}
        color="glyph-dark mb-0"
        popoverVersion={true}
      />
    </div>
  );
};
CalendarSidebarHeader.propTypes = {
  sidebarType: PropTypes.string,
  loadingCalendar: PropTypes.bool,
  handlePreviousClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  selectedEvent: PropTypes.object,
  takenDayCount: PropTypes.number,
  dayCount: PropTypes.number,
  startDay: PropTypes.object,
  takenMeds: PropTypes.number,
  totalMeds: PropTypes.number,
  toggleSidebar: PropTypes.func
};

const LeafletSidebarBody = ({ leaflet }) => {
  return (
    <div className="py-4  text-wrap">
      <div className="px-7">
        <div
          className="primary-underline pt-5"
          dangerouslySetInnerHTML={{
            __html: leaflet.leaflet
          }}
        />
      </div>
    </div>
  );
};
LeafletSidebarBody.propTypes = {
  leaflet: PropTypes.object
};

const DDISidebarBody = ({
  reviewDrug,
  suggestion,
  clinicalInteractions,
  table,
  medicationName,
  allInteractions
}) => {
  return (
    <div className={classnames("py-4  text-wrap")}>
      {reviewDrug ? (
        <div className="px-7">
          <h4 className="mt-2 mb-4">{suggestion}</h4>
          <div className="pb-6">
            {reviewDrug.drugDescription?.substring(
              reviewDrug.drugDescription.indexOf(":") + 2
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {clinicalInteractions ? (
        <div className="px-7 pb-4">
          We've detected {clinicalInteractions.length + " "}
          {allInteractions
            ? "Interaction(s)"
            : "Severe Drug-to-Drug Interaction(s)"}{" "}
          {!medicationName ? (
            <> by scanning this patient’s mobile app medication list.</>
          ) : (
            <>for the patient's medication {medicationName}</>
          )}
        </div>
      ) : (
        <> </>
      )}

      {table}
    </div>
  );
};
DDISidebarBody.propTypes = {
  reviewDrug: PropTypes.object,
  suggestion: PropTypes.string,
  clinicalInteractions: PropTypes.array,
  table: PropTypes.object,
  medicationName: PropTypes.string,
  allInteractions: PropTypes.bool
};

const CalendarSidebarBody = ({
  sidebarType,
  MedDropdown,
  loadingCalendar,
  calendar,
  setSidebarType,
  morningMeds,
  noonMeds,
  eveningMeds,
  nightMeds,
  prnMeds
}) => {
  return (
    <div
      className={classnames(
        { "pl-5 pt-3 pb-4": sidebarType === "calendar" },
        { "py-4": sidebarType !== "calendar" },
        "text-wrap"
      )}
    >
      <div>
        {sidebarType === "calendar" ? (
          <>
            <div className="mb-4 w-50">{MedDropdown}</div>
            <div>{!loadingCalendar && calendar}</div>
          </>
        ) : (
          <></>
        )}
        {loadingCalendar ? (
          <div
            className={classnames(
              { "px-5": sidebarType === "event-history" },
              { "pt-3 pr-5": sidebarType !== "event-history" }
            )}
          >
            {sidebarType === "event-history" && (
              <div className="ph-item border-0 mt-3 ">
                <div className="ph-col-12">
                  <div className=" ph-row">
                    <div className="ph-col-5 medium"></div>
                  </div>
                </div>
              </div>
            )}
            <table className="w-100">
              <tbody>
                <TableSkeletons emptyColSpan={6} />
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>

      {sidebarType === "event-history" && !loadingCalendar ? (
        <>
          <div>
            <button
              className="btn btn-link pl-5"
              onClick={() => {
                setSidebarType("calendar");
              }}
            >
              <Left className="mr-3 glyph-link-blue" aria-label="back" />
              Back to Month View
            </button>
          </div>
          <BodyInfo
            morningMeds={morningMeds}
            noonMeds={noonMeds}
            nightMeds={nightMeds}
            eveningMeds={eveningMeds}
            prnMeds={prnMeds}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
CalendarSidebarBody.propTypes = {
  sidebarType: PropTypes.string,
  MedDropdown: PropTypes.object,
  loadingCalendar: PropTypes.bool,
  calendar: PropTypes.object,
  setSidebarType: PropTypes.func,
  morningMeds: PropTypes.array,
  noonMeds: PropTypes.array,
  eveningMeds: PropTypes.array,
  nightMeds: PropTypes.array,
  prnMeds: PropTypes.array
};

const ContactSidebarHeader = ({ toggleSidebar }) => {
  return (
    <div className="d-flex align-items-center p-5 bg-dark">
      <h4 className="text-white mb-0">Contact Center</h4>
      <CloseButton
        color="glyph-white"
        toggle={() => toggleSidebar(false)}
        popoverVersion={true}
      />
    </div>
  );
};
ContactSidebarHeader.propTypes = {
  toggleSidebar: PropTypes.func
};

const contactButtons = [
  {
    name: "app",
    icon: <SmartPhone className="glyph-gray-fill h20-w20-style" />,
    label: "App"
  },
  {
    name: "text",
    icon: <Chat className="glyph-gray-fill h20-w20-style" />,
    label: "Text",
    disabled: true
  },
  {
    name: "ivr",
    icon: <Comment className="glyph-gray-fill h20-w20-style" />,

    label: "IVR",
    disabled: true
  },
  {
    name: "live-call",
    icon: <PhoneCall className="glyph-gray-fill h20-w20-style" />,

    label: "Live Call",
    disabled: true
  }
];

const ContactSidebarBody = () => {
  const [activeButton, setActiveButton] = useState("app");
  const [activeActions, setActiveActions] = useState([]);
  return (
    <div className="py-4 px-5">
      <h4>Step 1</h4>
      <h6>How would you like to contact this patient?</h6>
      <div className="text-secondary mb-2">
        Text, IVR, and Live Call are coming soon.
      </div>
      <div className="d-flex justify-content-between mb-4">
        {contactButtons.map((button, i) => {
          return (
            <button
              key={button.name}
              className={classnames(
                { "active selected": activeButton === button.name },
                "btn btn-white text-secondary d-flex flex-column align-items-center btn-square"
              )}
              onClick={() => setActiveButton(button.name)}
              disabled={button.disabled}
            >
              {button.icon}
              <span>{button.label}</span>
            </button>
          );
        })}
      </div>
      <h4>Step 2</h4>
      <h6>Which Action Items would you like to address?</h6>
      <div className="my-3 pb-7">
        {claimsAlertData
          .filter((item) => item.severity !== "low")
          .map((item, i) => {
            return (
              <div
                key={`${i}-${item?.type}`}
                className={classnames(
                  { "active selected": activeActions.includes(item) },
                  "d-flex align-items-center justify-content-between px-3 py-1 mb-3 bg-white br-5px border-white border"
                )}
              >
                <label htmlFor={`${item.type}-${item.medicationName}`}>
                  <h5>{item.type}</h5>
                  <h6 className="mb-0">{item.medicationName}</h6>
                </label>
                <CustomInput
                  type="checkbox"
                  className="light-blue-checkmark"
                  id={`${item.type}-${item.medicationName}`}
                  onChange={() => {
                    if (activeActions.includes(item)) {
                      setActiveActions(
                        activeActions.filter((action) => action !== item)
                      );
                    } else {
                      setActiveActions([...activeActions, item]);
                    }
                  }}
                ></CustomInput>
              </div>
            );
          })}
      </div>
      <div className="pt-7 d-flex justify-content-between">
        <div>Contact Center is coming soon!</div>
        <button className="btn btn-primary" disabled>
          Next
        </button>
      </div>
    </div>
  );
};

const ReviewSidebar = ({
  user,
  isSidebarOpen,
  scrollPosition,
  isIE,
  is1600Width,
  toggleSidebar,
  suggestion,
  reviewDrug,
  table,
  clinicalInteractions,
  sidebarType,
  setSidebarType,
  calendar,
  selectedEvent,
  handleNextClick,
  handlePreviousClick,
  startDay,
  loadingCalendar,
  totalMeds,
  takenMeds,
  MedDropdown,
  goForwardOneMonth,
  goBackOneMonth,
  leaflet,
  medicationName,
  allInteractions,
  testId
}) => {
  const [morningMeds, setMorningMeds] = useState([]);
  const [noonMeds, setNoonMeds] = useState([]);
  const [eveningMeds, setEveningMeds] = useState([]);
  const [nightMeds, setNightMeds] = useState([]);
  const [prnMeds, setPrnMeds] = useState([]);
  const [dayCount, setDayCount] = useState(0);
  const [takenDayCount, setTakenDayCount] = useState(0);
  let today = new Date();
  today.setDate(today.getDate() - 1);

  useEffect(() => {
    if (sidebarType === "calendar" || sidebarType === "event-history") {
      setTakenDayCount(0);
      setDayCount(0);
      setMorningMeds([]);
      setNoonMeds([]);
      setEveningMeds([]);
      setNightMeds([]);
      setPrnMeds([]);
      if (selectedEvent?.extendedProps?.history) {
        selectedEvent.extendedProps.history.forEach((element) => {
          setDayCount((state) => state + 1);
          if (element.data.isTaken === 1)
            setTakenDayCount((state) => state + 1);
          let hour = dayjs(element.data.scheduledAt).hour();
          if (hour >= 12 && hour < 17) {
            setNoonMeds((state) => [...state, element]);
          } else if (hour >= 17 && hour < 21) {
            setEveningMeds((state) => [...state, element]);
          } else if (hour >= 21 && hour < 24) {
            setNightMeds((state) => [...state, element]);
          } else if (!hour && hour !== 0 && element.data.isTaken) {
            setPrnMeds((state) => [...state, element]);
          } else {
            setMorningMeds((state) => [...state, element]);
          }
        });
      }
    }
  }, [selectedEvent, setTakenDayCount, setDayCount, sidebarType]);

  //change commneted code using root.style.setProperty
  useEffect(() => {
    const root = document.documentElement;
    let num;
    if (user?.isSmartSession) {
      num = 47;
    } else if (isIE && is1600Width) {
      num = 74;
    } else if (isIE) {
      num = 52;
    }
    if (scrollPosition === 0) {
      root.style.setProperty("--top", `${num}`);
    } else if (scrollPosition < num) {
      root.style.setProperty("--top", `${num - scrollPosition}`);
    } else if (isIE) {
      root.style.setProperty("--top", `0`);
    } else {
      root.style.setProperty("--top", `52`);
    }
  }, [is1600Width, isIE, scrollPosition, user?.isSmartSession]);

  useEffect(() => {
    if (isSidebarOpen) {
      // add class to body to prevent scrolling
      document.body.classList.add("no-scroll");
    }
    return () => {
      // remove class from body to allow scrolling
      document.body.classList.remove("no-scroll");
    };
  }, [isSidebarOpen]);

  return (
    <div
      data-testid={testId || `${sidebarType}-sidebar`}
      className={classnames(
        { in: isSidebarOpen },
        { "smart-sidebar": user?.isSmartSession },
        { "bg-gray": sidebarType === "contact-center" },
        "d-flex flex-column text-left pb-7 dynamic-top"
      )}
      id="review-sidebar"
    >
      <button
        data-testid={`${sidebarType}-sidebar-toggle`}
        className="toggle-button "
        tabIndex="0"
        onClick={() => toggleSidebar(false)}
      ></button>
      <div className="text w-100 pb-7 scrollable">
        {/* Header section */}
        {sidebarType === "leaflet" ? (
          <>
            <LeafletHeader leaflet={leaflet} toggleSidebar={toggleSidebar} />
            <LeafletSidebarBody leaflet={leaflet} />
          </>
        ) : (
          <></>
        )}
        {sidebarType === "contact-center" ? (
          <>
            <ContactSidebarHeader toggleSidebar={toggleSidebar} />
            <ContactSidebarBody />
          </>
        ) : (
          <></>
        )}
        {sidebarType === "ddi" ? (
          <>
            <DDISidebarHeader
              reviewDrug={reviewDrug}
              clinicalInteractions={clinicalInteractions}
              toggleSidebar={toggleSidebar}
              medicationName={medicationName}
              allInteractions={allInteractions}
            />{" "}
            <DDISidebarBody
              reviewDrug={reviewDrug}
              suggestion={suggestion}
              clinicalInteractions={clinicalInteractions}
              table={table}
              medicationName={medicationName}
              allInteractions={allInteractions}
            />
          </>
        ) : (
          <></>
        )}
        {sidebarType === "calendar" || sidebarType === "event-history" ? (
          <>
            <CalendarSidebarHeader
              sidebarType={sidebarType}
              loadingCalendar={loadingCalendar}
              handlePreviousClick={
                sidebarType === "calendar"
                  ? goBackOneMonth
                  : handlePreviousClick
              }
              handleNextClick={
                sidebarType === "calendar" ? goForwardOneMonth : handleNextClick
              }
              selectedEvent={selectedEvent}
              takenDayCount={takenDayCount}
              dayCount={dayCount}
              startDay={startDay}
              takenMeds={takenMeds}
              totalMeds={totalMeds}
              toggleSidebar={toggleSidebar}
            />
            <CalendarSidebarBody
              sidebarType={sidebarType}
              MedDropdown={MedDropdown}
              loadingCalendar={loadingCalendar}
              calendar={calendar}
              setSidebarType={setSidebarType}
              morningMeds={morningMeds}
              noonMeds={noonMeds}
              eveningMeds={eveningMeds}
              nightMeds={nightMeds}
              prnMeds={prnMeds}
            />
          </>
        ) : (
          <></>
        )}
        {/* Body of review sidebar */}
      </div>
    </div>
  );
};

ReviewSidebar.propTypes = {
  user: PropTypes.object,
  isSidebarOpen: PropTypes.bool,
  scrollPosition: PropTypes.number,
  isIE: PropTypes.bool,
  is1600Width: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  suggestion: PropTypes.string,
  reviewDrug: PropTypes.object,
  table: PropTypes.object,
  clinicalInteractions: PropTypes.array,
  sidebarType: PropTypes.string,
  setSidebarType: PropTypes.func,
  calendar: PropTypes.object,
  selectedEvent: PropTypes.object,
  handleNextClick: PropTypes.func,
  handlePreviousClick: PropTypes.func,
  startDay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loadingCalendar: PropTypes.bool,
  totalMeds: PropTypes.number,
  takenMeds: PropTypes.number,
  MedDropdown: PropTypes.object,
  goForwardOneMonth: PropTypes.func,
  goBackOneMonth: PropTypes.func,
  leaflet: PropTypes.object,
  medicationName: PropTypes.string,
  allInteractions: PropTypes.bool,
  testId: PropTypes.string
};

export { ReviewSidebar };
